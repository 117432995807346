/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import {ReactNode} from 'react';
import {Document} from '@contentful/rich-text-types';
import {IntlShape} from 'react-intl';
import {IGatsbyImageData} from 'gatsby-plugin-image';
import {ClassNamesArg} from '@emotion/css';

export * from './errorTypes';
export * from './typeChecks';
export * from './utilities';
export * from './application';
export * from './account';

/// ////////////////////////
/// INTERNAL MISC TYPES ///
/// ////////////////////////

export enum Alignment {
  LEFT = 'alignLeft',
  CENTER = 'alignCenter',
  RIGHT = 'alignRight',
}

export enum BooleanString {
  TRUE = 'true',
  FALSE = 'false',
  NULL = 'null',
}

export enum RadioYesNoField {
  YES = 'yes',
  NO = 'no',
}

// For soft-type checking, use sparingly with comprehensive tests
export type ObjectOfAny = {
  [key: string]: any;
};

export type ObjectOfStrings = {
  [key: string]: string;
};

export type ArrayOfAny = any[];
// window extensions

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type TypeWithKey<PassedInterface> = PassedInterface & {
  key: string;
};

export type FormatMessageFunction = IntlShape['formatMessage'];

// @todo -- type for the ErrorMessages -- will look something like `Errors<T>`
export type Errors = any;

export type LinkProp = {
  href: string;
  label: string;
  onClick?: () => void;
  testId?: string;
};

/// /////////////////////
/// DOC UPLOAD TYPES ///
/// /////////////////////

export enum ErrorCodes {
  FILE_TOO_LARGE = 'file-too-large',
  TOO_MANY_FILES = 'too-many-files',
  FILE_INVALID_TYPE = 'file-invalid-type',
}

export enum DateFormat {
  FULL_DATE = 'yyyy-mm-dd',
  MONTH_AND_YEAR = 'yyyy-mm',
}

/// /////////////////////
/// CONTENTFUL TYPES ///
/// /////////////////////

export interface ContentfulContent {
  raw: string;
  references?: ObjectOfAny[];
}

export interface ContentJson extends Document {
  content: ArrayOfAny;
}

export type Conversion = {
  name: string;
  slug: string;
  description: string;
  content: ContentfulContent;
  hero?: Hero;
  isNoindex: boolean | null;
  showHowDoesItWork: boolean;
  showOurProducts: boolean;
  showLogos: boolean;
  contentfulId: string;
  metaDescription?: string;
  metaTitle?: string;
};

export type Hero = {
  image?: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  title?: string;
  source?: string;
  showCheckpointsOnHero?: boolean;
};

export type ArticleDialogueWithCta = {
  title: string;
  description: string;
  ctaText: string;
  slug: string;
};

// these must match up with contentful
// added to not allow users to screw this up
export enum PartnerTracking {
  SIMPLY_BUSINESS = 'simplyBusiness',
}

export type Partner = {
  logo: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
    file?: {
      url: string;
    };
  };
  slug?: string; // only need it for the purposes of building the page
  name: string;
  affid: string;
  description?: string;
  content?: ContentfulContent;
  hero?: Hero;
  accordion?: Accordion;
  trackingScript?: PartnerTracking;
  isBrsPartner: boolean;
};

export type Advisor = {
  description: string;
  logo: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  slug?: string; // only need it for the purposes of building the page
  name: string;
  affid: string;
  hero: Hero | null;
};

// must match strings in contentful
export enum SupplierType {
  ENERGY = 'energy',
  INSURANCE = 'insurance',
  FOREX = 'forex',
}

export type Supplier = {
  hero: Hero;
  logo: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  description: {
    description: string;
  };
  name: string;
  slug: string;
  supplierUrl: string;
  type: SupplierType;
  accordion?: Accordion;
  metaDescription?: {
    metaDescription: string;
  };
};

/* Customer Stories */
export interface HomePageCustomerStory {
  id: string;
  name: string;
  quote: {
    quote: string;
  };
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  businessName: string;
  slug: string;
}

export interface HubCustomerStory {
  id: string;
  businessName: string;
  name: string;
  slug: string;
  industry: {
    name: string;
    icon: {
      file: {
        url: string;
      };
    };
  };
  loanType: {
    name: string;
  }[];
  quote: {
    quote: string;
  };
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

export interface CustomerStory {
  id: string;
  businessName: string;
  content: ContentfulContent;
  metaTitle: string;
  metaDescription: string;
  name: string;
  slug: string;
  date: string;
  industry: {
    name: string;
    icon: {
      file: {
        url: string;
      };
    };
  };
  loanType: {
    name: string;
    url: string;
  }[];
  quote: {
    quote: string;
  };
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

export type CardArticle = {
  name: string;
  shortDescription: string;
  path: string;
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

/* Blog Types */
export type Author = {
  name?: string;
  jobTitle?: string;
  slug: string;
  linkedInLink: string;
  knowledge_page?: TKnowledgePage[];
  blog_article?: BlogArticle[];
  external_article?: ExternalArticles[];

  shortBio?: {
    shortBio?: string;
  };
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export type ExternalArticles = {
  id: string;
  link: string;
  publication: string;
  createdAt: string;
  title: string;
  date?: string;
  description?: {
    description?: string;
  };
  externalArticleCardImage: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export type TKnowledgePage = {
  id: string;
  seo: {
    type: string;
    contents: {
      contents: string;
    };
  };
  author?: Author;
  category?: KnowledgePageCategory;
  content?: ContentfulContent;
  createdAt: string;
  customCtaText?: string;
  description: string;
  dialogueWithCta?: ArticleDialogueWithCta;
  hero: Hero;
  howToGuideCategory?: {
    slug: string;
    title: string;
  };
  heroTitle?: string;
  heroDescription?: {
    heroDescription?: string;
  };
  name: string;
  relatedArticles?: Partial<ContentfulContent>;
  robots?: string;
  slug: string;
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export type KnowledgePageCategory = {
  title: string;
};

export type BlogCategory = {
  name: string;
  slug: string;
  title?: string;
};

export type BlogRelatedArticles = {
  name: string;
  slug: string;
  category: BlogCategory;
};

export type BlogArticle = {
  articleCardImage: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  author?: Author;
  category: BlogCategory;
  content?: ContentfulContent;
  createdAt: string;
  date: string | number | Date;
  dialogueWithCta?: ArticleDialogueWithCta;
  id: string;
  hero?: Hero;
  metaName?: string;
  metaDescription?: string;
  metaRobots?: string;
  name: string;
  relatedArticles?: BlogRelatedArticles[];
  shortDescription: {internal: {content: string}};
  description: string;
  slug: string;
};

// Business Current Account
export type BCAProduct = {
  contentful_id: string;
  accountFee: string;
  bankTransfersFee: string;
  overdraft: string;
  access: string;
  title: string;
  linkToKnowledgePage?: {slug: string};
  description: {internal: {content: string}};
  externalUrl?: string;
};

export type BCAProvider = {
  contentful_id: string;
  name: string;
  logo: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  product: BCAProduct[];
};

// Contentful Driven Website Single Pages
export type WebsiteSinglePage = {
  title: string;
  metaDescription?: string;
  slug: string;
  hero?: Hero;
  content: ContentfulContent;
};

// Accordion
export type Accordion = {
  accordionName: string;
  id?: string;
  items: ContentfulAccordionItem[];
  subheading?: string;
};

// coming in from contentful
export interface ContentfulAccordionItem {
  label: string;
  content: ContentfulContent;
  id: string;
}

// used in our code
export interface AccordionItemProps {
  label: string;
  children: ReactNode;
  id?: string;
  labelClass?: string;
}

// custom analytics for Partners
export enum AnalyticsType {
  PIXEL = 'pixel',
  SCRIPT = 'script',
  SCRIPT_SRC = 'scriptSrc',
}

export interface Analytics {
  code: string;
  type: AnalyticsType;
}

export interface CustomAnalytics {
  register: Analytics[];
  leadCreated: Analytics[];
  leadSubmitted: Analytics[];
}

export type StyleOverrides<T> = {
  [P in keyof T]?: T[P] | ClassNamesArg;
};

export type SelectOption = {
  label: string;
  value: string;
  description?: string;
};

export type GatsbyImageDataWithTitle = {
  title: string;
  gatsbyImageData: IGatsbyImageData;
  description?: string;
};

export type MinMaxLimits = {
  min: number;
  max: number;
};
